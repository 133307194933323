function mapEngPath(inputPath) {
    var data = require('./vie_eng.json');
    let eng_audio = data[inputPath];
    return eng_audio
}

// Export the mapPath function
export { mapEngPath };

function mapJpPath(inputPath) {
    var data = require('./vie_jp.json');
    let jp_audio = data[inputPath];
    return jp_audio
}

// Export the mapPath function
export { mapJpPath };