export const AUDIO_ACTION = {
    DOWN_RIGHT_LEG: 'ban_hay_ha_thap_chan_phai_xuong.wav',
    DOWN_LEFT_LEG: 'ban_hay_ha_thap_chan_trai_xuong.wav',
    DOWN_RIGHT_HAND: 'ban_hay_ha_thap_tay_phai_xuong.wav',
    DOWN_LEFT_HAND: 'ban_hay_ha_thap_tay_trai_xuong.wav',
    UP_RIGHT_LEG: 'ban_hay_nang_chan_phai_len.wav',
    UP_LEFT_LEG: 'ban_hay_nang_chan_trai_len.wav',
    UP_RIGHT_HAND: 'ban_hay_nang_tay_phai_len.wav',
    UP_LEFT_HAND: 'ban_hay_nang_tay_trai_len.wav',

    INCORRECT_RIGHT_LEG: 'audio/dong_tac_chan_phai_chua_dung_190923113958.wav',
    INCORRECT_LEFT_LEG: 'audio/dong_tac_chan_trai_chua_dung_190923113944.wav',
    INCORRECT_NECK: 'audio/dong_tac_co_chua_dung_190923114130.wav',
    INCORRECT_RIGHT_HIP: 'audio/dong_tac_hong_phai_chua_dung_190923114024.wav',
    INCORRECT_LEFT_HIP: 'audio/dong_tac_hong_trai_chua_dung_190923114040.wav',
    INCORRECT_RIGHT_HAND: 'audio/dong_tac_tay_phai_chua_dung_190923105243.wav',
    INCORRECT_LEFT_HAND: 'audio/dong_tac_tay_trai_chua_dung_190923113928.wav',
    INCORRECT_RIGHT_SHOULDER: 'audio/dong_tac_vai_phai_chua_dung_190923114114.wav',
    INCORRECT_LEFT_SHOULDER: 'audio/dong_tac_vai_trai_chua_dung_190923114058.wav',

    // 
    RIGHT_LEG: 'audio/dong_tac_chan_phai_chua_dung_190923113958.wav',
    LEFT_LEG: 'audio/dong_tac_chan_trai_chua_dung_190923113944.wav',
    NECK: 'audio/dong_tac_co_chua_dung_190923114130.wav',
    RIGHT_HIP: 'audio/dong_tac_hong_phai_chua_dung_190923114024.wav',
    LEFT_HIP: 'audio/dong_tac_hong_trai_chua_dung_190923114040.wav',
    RIGHT_HAND: 'audio/dong_tac_tay_phai_chua_dung_190923105243.wav',
    LEFT_HAND: 'audio/dong_tac_tay_trai_chua_dung_190923113928.wav',
    RIGHT_SHOULDER: 'audio/dong_tac_vai_phai_chua_dung_190923114114.wav',
    LEFT_SHOULDER: 'audio/dong_tac_vai_trai_chua_dung_190923114058.wav',

    // HDSD
    QUY_TRINH: 'audio/quy_trinh_su_dung_Yoga_AI_v3.mp3',
    CHINH_XAC: 'audio/am-thanh-tra-loi-dung-chinh-xac.mp3',
    QUY_TRINH_TA: 'audio/ai_instructor_english.mp3',
    QUY_TRINH_JP: 'audio/ai_instructor_jp.mp3',

    //Dieu khien bang tay
    TAY_DANG_NGANG: 'audiov2/Bạn_hãy_đưa_hai_tay_dang_ngang.mp3',
    CONG_LUNG: 'audiov2/Cong_lưng.mp3',
    CUON_LUNG: 'audiov2/Cuộn_tròn_lưng.mp3',
    CHAN_PHAI_VE_SAU: 'audiov2/Đưa_chân_phải_về_phía_sau.mp3',
    CHAN_PHAI_VE_TRUOC: 'audiov2/Đưa_chân_phải_về_phía_trước.mp3',
    CHAN_TRAI_VE_SAU: 'audiov2/Đưa_chân_trái_về_phía_sau.mp3',
    CHAN_TRAI_VE_TRUOC: 'audiov2/Đưa_chân_trái_về_phía_trước.mp3',
    CO_THE_VE_GIUA: 'audiov2/Đưa_cơ_thể_về_giữa.mp3',
    HONG_SANG_PHAI: 'audiov2/Đưa_hông_sang_phải.mp3',
    HONG_SANG_TRAI: 'audiov2/Đưa_hông_sang_trái.mp3',
    HONG_VE_GIUA: 'audiov2/Đưa_hông_về_giữa.mp3',
    NGUOI_VE_TRUOC: 'audiov2/Gập_người_về_phía_trước.mp3',
    LUNG_THANG: 'audiov2/Giữ_lưng_thẳng.mp3',
    VAI_XUONG: 'audiov2/Hạ_vai_xuống.mp3',
    TAY_XUOI: 'audiov2/Hai_tay_xuôi.mp3',
    VAI_THANG: 'audiov2/Hai_vai_trên_một_đường_thẳng.mp3',
    DUI_VUONG: 'audiov2/Mở_đùi_vuông_góc.mp3',
    GOI_PHAI_VUONG: 'audiov2/Mở_gối_phải_vuông_góc.mp3',
    GOI_TRAI_VUONG: 'audiov2/Mở_gối_trái_vuông_góc.mp3',
    MO_HONG: 'audiov2/Mở_hông.mp3',
    VAI_LEN: 'audiov2/Nâng_vai_lên.mp3',
    LUNG_SAU: 'audiov2/Ngả_lưng_ra_sau.mp3',
    LUON_SANG_TRAI: 'audiov2/Nghiêng_lườn_sang_phải.mp3',
    LUON_SANG_PHAI: 'audiov2/Nghiêng_lườn_sang_trái.mp3',
    TAY_PHAI_LEN: 'audiov2/Tay_phải_hướng_thẳng_lên_chần.mp3',
    TAY_TRAI_LEN: 'audiov2/Tay_trái_hướng_thẳng_lên_chần.mp3',
    THIET_CHAT_DUI: 'audiov2/Thiết_chặt_cơ_đùi.mp3',
    GOI_PHAI_VUONG_CO_CHAN: 'audiov2/Thu_đầu_gối_phải_vuông_góc_cổ_chân.mp3',
    GOI_TRAI_VUONG_CO_CHAN: 'audiov2/Thu_đầu_gối_trái_vuông_góc_cổ_chân.mp3'
}

/* export const AUDIO = {
    RIGHT_LEG: 'audio/dong_tac_chan_phai_chua_dung_190923113958.wav',
    LEFT_LEG: 'audio/dong_tac_chan_trai_chua_dung_190923113944.wav',
    NECK: 'audio/dong_tac_co_chua_dung_190923114130.wav',
    RIGHT_HIP: 'audio/dong_tac_hong_phai_chua_dung_190923114024.wav',
    LEFT_HIP: 'audio/dong_tac_hong_trai_chua_dung_190923114040.wav',
    RIGHT_HAND: 'audio/dong_tac_tay_phai_chua_dung_190923105243.wav',
    LEFT_HAND: 'audio/dong_tac_tay_trai_chua_dung_190923113928.wav',
    RIGHT_SHOULDER: 'audio/dong_tac_vai_phai_chua_dung_190923114114.wav',
    LEFT_SHOULDER: 'audio/dong_tac_vai_trai_chua_dung_190923114058.wav',
} */

export const handlePlayAudio = (audioAction) => {
    // const fileNameAudio = AUDIO_ACTION[audioAction]
    // console.log(fileNameAudio)
    // const audioPath = `/${fileNameAudio}`;
    console.log('handlePlayAudio:', audioAction)
    const audio = new Audio(audioAction);
    audio.play();
}

export const handlePlayAudioAndWait = async (audioAction) => {
    const playAudio = (audio) => {
        return new Promise(res => {
            audio.play()
            audio.onended = res
        })
    }
    const fileNameAudio = AUDIO_ACTION[audioAction]
    console.log(fileNameAudio)
    const audioPath = `/${fileNameAudio}`;
    const audio = new Audio(audioPath);
    await playAudio(audio);
}