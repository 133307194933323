import axios from "axios";

const keyPointApiEndPoint = "https://admin.visedu.vn:10081"

const request = axios.create({
    baseURL: keyPointApiEndPoint,
    timeout: 30000,
});

export const keypointApi = {
    getMetadata: (keyPointHost, fileName) => request({
        baseURL: keyPointHost,
        url: `/keypoint/${fileName}/metadata.json`
    }),
    getChunkFile: (keyPointHost, fileName, chunkFileName) => request({
        baseURL: keyPointHost,
        url: `/keypoint/${fileName}/${chunkFileName}`
    }),
    find: (fileName) => request({
        url: `/v1/public/bookChapter/keypoint?fileName=${fileName}`
    }),
    createLog: (data) => request({
        url: '/v1/public/log/yoga/info',
        method: 'post',
        data
    }),
    createLogfile: (log, file) => {
        const formData = new FormData();
        formData.append('log', log);
        formData.append('file', file);
        return request({
            url: '/v1/public/log/yoga/infoFile',
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
}


