import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import vi from './locales/VI.json'
import en from './locales/EN.json'
import jp from './locales/JP.json'

export enum Language {
    Vi = "VI",
    En = "EN",
    JP = "JP",
}


const resources = {
    vi: {
        translation: vi
    },
    en: {
        translation: en
    },
    jp: {
        translation: jp
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: Language.En,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;


